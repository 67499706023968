import React from 'react';
import SlidersLayout from './SlidersLayout';
import { useSliderScroll } from './useSliderScroll';

const SlidersDefault = ({
  children,
  classList,
  titleNodes,
  link,
  pagination = false,
  testId,
  viewAll = false,
  customNavigation = false,
  viewAllAsCard = false,
  SeeAllCard,
  gap,
  isRoundNavigation = false,
  showScrollBar = false,
}) => {
  const { onScrollForward, onScrollBack, scrollLeft, isScrolledToEnd, sliderContainerRef, onScroll } = useSliderScroll(
    gap,
    children
  );
  return (
    <SlidersLayout
      classList={classList}
      titleNodes={titleNodes}
      link={link}
      pagination={pagination}
      testId={testId}
      viewAll={viewAll}
      customNavigation={customNavigation}
      scrollLeft={scrollLeft}
      onScrollForward={onScrollForward}
      onScrollBack={onScrollBack}
      isScrolledToEnd={isScrolledToEnd}
      ref={sliderContainerRef}
      viewAllAsCard={viewAllAsCard}
      SeeAllCard={SeeAllCard}
      onScroll={onScroll}
      isRoundNavigation={isRoundNavigation}
      showScrollBar={showScrollBar}
    >
      {children}
    </SlidersLayout>
  );
};

export default SlidersDefault;
