/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import { ReactComponent as ArrowLeft } from '../../images/icons/arrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../images/icons/arrowRight.svg';
import { ReactComponent as SeeAll } from '../../images/icons/seeAll.svg';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styles from './Sliders.module.scss';

const SlidersLayout = React.forwardRef(
  (
    {
      children,
      classList,
      titleNodes = [],
      link,
      pagination = false,
      testId,
      viewAll = false,
      customNavigation = false,
      scrollLeft,
      onScrollForward,
      onScrollBack,
      isScrolledToEnd,
      viewAllAsCard = false,
      SeeAllCard,
      onScroll,
      isRoundNavigation = false,
      showScrollBar = false,
    },
    ref
  ) => {
    const [firstTitlePart, secondTitlePart] = titleNodes;
    return (
      <div className={classNames(classList.root || styles.slider, 'row')} data-testid={testId}>
        {!!firstTitlePart && (
          <h2 className={classNames(styles.title, 'col-12-xs')}>
            <span>{firstTitlePart}</span> {secondTitlePart}
          </h2>
        )}
        <div className={classNames(styles.sliderContainer, 'col-12-xs')} data-testid={`${testId}Container`}>
          {!customNavigation && scrollLeft > 0 && (
            <span
              className={classNames(classList.color, styles.navigationPrev, {
                [styles.roundNavigation]: isRoundNavigation,
              })}
              onClick={onScrollBack}
              data-testid={`${testId}ArrowLeft`}
            >
              <ArrowLeft />
            </span>
          )}
          <div
            className={classNames(styles.slides, classList.slides, {
              [styles.viewAll]: viewAll,
              [styles.slidesHiddenScroll]: !showScrollBar,
            })}
            ref={ref}
            onScroll={onScroll}
          >
            {children.map((card, index) => {
              return (
                <article className={classList.card} key={index}>
                  {card}
                </article>
              );
            })}
            {viewAll && link && (
              <>
                {!viewAllAsCard ? (
                  <article className={classNames(classList.seeAll || styles.seeAll)}>
                    <ArrowRight />

                    <Link to={link}>
                      <FormattedMessage id="footer.viewAll" defaultMessage="View all" />
                    </Link>
                  </article>
                ) : SeeAllCard ? (
                  <SeeAllCard />
                ) : (
                  <article className={classNames(classList.card)}>
                    <Link to={link} className={styles.seeAllCard}>
                      <SeeAll />

                      <FormattedMessage id="footer.viewAll" defaultMessage="View all" />
                    </Link>
                  </article>
                )}
              </>
            )}
          </div>
          {!customNavigation && !isScrolledToEnd && (
            <span
              className={classNames(classList.color, styles.navigationNext, {
                [styles.roundNavigation]: isRoundNavigation,
              })}
              onClick={onScrollForward}
              data-testid={`${testId}ArrowRight`}
            >
              <ArrowRight />
            </span>
          )}
        </div>
        {pagination && (
          <div className={styles.pagination}>
            {children.map((page, i) => (
              <span key={`pagesThumb${i}`} className={styles.thumb}></span>
            ))}
          </div>
        )}
      </div>
    );
  }
);

SlidersLayout.displayName = 'SlidersLayout';
export default SlidersLayout;
