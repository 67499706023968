import React from 'react';
import SlidersLayout from './SlidersLayout';

const SlidersCustom = ({
  children,
  classList,
  titleNodes,
  link,
  pagination = false,
  testId,
  viewAll = false,
  customNavigation = true,
  onScrollForward,
  onScrollBack,
  scrollLeft,
  isScrolledToEnd,
  sliderContainerRef,
  viewAllAsCard = false,
  SeeAllCard,
  onScroll,
  isRoundNavigation = false,
}) => {
  return (
    <SlidersLayout
      classList={classList}
      titleNodes={titleNodes}
      link={link}
      pagination={pagination}
      testId={testId}
      viewAll={viewAll}
      customNavigation={customNavigation}
      scrollLeft={scrollLeft}
      onScrollForward={onScrollForward}
      onScrollBack={onScrollBack}
      isScrolledToEnd={isScrolledToEnd}
      ref={sliderContainerRef}
      viewAllAsCard={viewAllAsCard}
      SeeAllCard={SeeAllCard}
      onScroll={onScroll}
      isRoundNavigation={isRoundNavigation}
    >
      {children}
    </SlidersLayout>
  );
};

export default SlidersCustom;
